<template>
  <div class="mb-4">
    <app-tour :steps="steps" />
    <b-alert :variant="'primary'" show class="cursor-pointer">
      <div class="alert-body">
        <strong>
          <feather-icon icon="BookOpenIcon" class="mr-25" />
          Every great system starts with a solid foundation. Create forms
          effortlessly to capture data and manage entries for seamless
          operations.
        </strong>
      </div>
    </b-alert>
    <b-row class="match-height">
      <b-col cols="12">
        <b-badge variant="danger" class="badge-glow yo-guide button" @click="tourStart">
          <feather-icon icon="HelpCircleIcon" /> Guide
        </b-badge>
      </b-col>
      <b-col cols="12" md="12">
        <b-card id="tour-badge-info">
          <validation-observer ref="yoBadgeRule" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  <b-badge pill variant="primary"> A </b-badge>
                  Badge Info
                </h5>
                <small class="text-muted">
                  <strong>Enter Your Badge Info.</strong>
                </small>
              </b-col>
              <b-col md="12" cols="12">
                <validation-provider #default="{ errors }" name="Badge" rules="required">
                  <b-form-group label="Badge" label-for="badge_id" :state="errors.length > 0 ? false : null">
                    <!-- :options="badgeNameLogin" -->
                    <v-select id="badge_id" v-model="form.badge_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="badgeName" :reduce="(option) => option.value" :selectable="(option) => !option.value.includes('select_value')
                        " disabled="true" label="text" item-value="value" item-text="text">
                      <template #option="{ avatar, text }">
                        <!-- <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                    /> -->
                        <span class="ml-50 align-middle"> {{ text }}</span>
                      </template>

                      <template #selected-option="{ avatar, text }">
                        <!-- <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                    /> -->
                        <span class="ml-50 align-middle"> {{ text }}</span>
                      </template>
                    </v-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="12" cols="12">
                <validation-provider #default="{ errors }" name="Kunjungan Ke Perusahaan" rules="required">
                  <b-form-group label="Kunjungan Ke Perusahaan" label-for="visitor_cat_id"
                    :state="errors.length > 0 ? false : null">
                    <v-select id="visitor_cat_id" v-model="form.visitor_cat_id"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="categoryName"
                      :reduce="(option) => option.value" :selectable="(option) => !option.value.includes('select_value')
                        " label="text" item-value="value" item-text="text">
                      <template #option="{ color, text }">
                        <b-spinner small type="grow" :variant="color" />
                        <span class="ml-50 align-middle">
                          <strong>{{ text }}</strong></span>
                      </template>

                      <template #selected-option="{ color, text }">
                        <b-spinner small type="grow" :variant="color" />
                        <span class="ml-50 align-middle">
                          <strong>{{ text }}</strong></span>
                      </template>
                    </v-select>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="12" cols="12">
                <!-- Phone -->
                <b-form-group label="Nomor Whatsapp * ( Ex: 8155 7777 33 )" label-for="phone">
                  <validation-provider #default="{ errors }" name="Nomor Whatsapp *" vid="phone" :rules="{
                    required: true
                  }">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        ID (+62)
                      </b-input-group-prepend>
                      <cleave id="phone" v-model="form.phone" class="form-control" :raw="false" :options="options.phone"
                        placeholder="8155 7777 33" :state="errors.length > 0 ? false : null" />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-col>

      <b-col cols="12" md="6" v-if="false">
        <b-card>
          <validation-observer ref="yoSocialLinksRule" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  <b-badge pill variant="primary"> B </b-badge> Social Links
                  <b-badge variant="success"> Opsional </b-badge>
                </h5>
                <small class="text-muted">
                  <strong>Enter Your Social Links Opsional</strong>
                </small>
              </b-col>
              <b-col md="6" cols="12">
                <b-form-group label="Twitter" label-for="twitter">
                  <validation-provider #default="{ errors }" name="twitter" rules="twitter">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <feather-icon icon="TwitterIcon" />
                      </b-input-group-prepend>
                      <b-form-input id="twitter" v-model="form.twitter" type="twitter"
                        :state="errors.length > 0 ? false : null" placeholder="salpalaran" />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" cols="12">
                <b-form-group label="Facebook" label-for="facebook">
                  <validation-provider #default="{ errors }" name="facebook" rules="facebook">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <feather-icon icon="FacebookIcon" />
                      </b-input-group-prepend>
                      <b-form-input id="facebook" v-model="form.facebook" type="facebook"
                        :state="errors.length > 0 ? false : null" placeholder="salpalaran" />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" cols="12">
                <b-form-group label="Instagram" label-for="instagram">
                  <validation-provider #default="{ errors }" name="instagram" rules="instagram">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <feather-icon icon="InstagramIcon" />
                      </b-input-group-prepend>
                      <b-form-input id="instagram" v-model="form.instagram" type="instagram"
                        :state="errors.length > 0 ? false : null" placeholder="salpalaran" />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" cols="12">
                <b-form-group label="Linkedin" label-for="linkedin">
                  <validation-provider #default="{ errors }" name="linkedin" rules="linkedin">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <feather-icon icon="LinkedinIcon" />
                      </b-input-group-prepend>
                      <b-form-input id="linkedin" v-model="form.linkedin" type="linkedin"
                        :state="errors.length > 0 ? false : null" placeholder="salpalaran" />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-col>

      <b-col cols="12" md="6">
        <b-card id="tour-personal-info">
          <validation-observer ref="yoPersonalInfoRule" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  <b-badge pill variant="primary"> B </b-badge> Personal Info
                </h5>
                <small class="text-muted">
                  <strong>Enter Your Personal Info.</strong>
                </small>
              </b-col>
              <b-col cols="12">
                <b-alert :variant="'danger'" show class="cursor-pointer">
                  <div class="alert-body">
                    <strong>
                      <feather-icon icon="BookOpenIcon" class="mr-25" />
                      <strong>
                        <i>Sudah pernah memiliki QRCode tetapi
                          <strong><b-badge variant="danger" class="badge-glow button">
                              <feather-icon icon="UserXIcon" />
                              expired
                            </b-badge></strong>
                          ?</i>
                        <span id="tour-qrcode" @click="$router.push({ name: 'reuse-qrcode' })"
                          style="cursor: pointer; color: blue">
                          Click Reuse / Renew QRCode
                        </span>
                      </strong>
                    </strong>
                  </div>
                </b-alert>
              </b-col>
              <b-col md="12" cols="12">
                <b-card border-variant="primary" header-border-variant="primary" header-text-variant="primary">
                  <b-row>
                    <b-col md="12" cols="12">
                      <h4 class="mb-1">Document KTP</h4>
                      <!-- <b-img
                        ref="refPreviewEl"
                        :src="no_ktp_file"
                        height="110"
                        width="170"
                        class="rounded mr-2 mb-1 mb-md-0"
                      />
                      <b-form-group
                        class="mt-1"
                        label=""
                        label-for="vi-schedule-no_ktp_file"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="File KTP"
                          vid="File KTP"
                          rules="required"
                        >
                          <b-form-file
                            id="form-ktp-file"
                            ref="refInputEl"
                            v-model="form.no_ktp_file"
                            placeholder="Choose a file KTP or drop it here..."
                            accept=".jpeg, .jpg, .png"
                            :state="errors.length > 0 ? false : null"
                            @input="inputImageRenderer"
                          />
                        </validation-provider>
                      </b-form-group> -->
                      <div v-if="text">
                        <b-card border-variant="success" header-border-variant="success" header-text-variant="success">
                          <small>System berhasil membaca
                            <strong>Nomor NIK : </strong>
                            <span class="cursor-pointer" v-b-tooltip.hover title="Click to copy"
                              @click="copyText(text)">{{
                              text }} <feather-icon icon="CopyIcon" />
                            </span>
                            , jika benar maka Click nomor NIK (copy) dan paste
                            ke kolom pengisian
                            <strong>Nomor KTP</strong>.</small>
                        </b-card>
                      </div>
                    </b-col>
                    <b-col md="12" cols="12">
                      <b-row>
                        <b-col cols="12">
                          <b-form-group label="Nomor KTP *" label-for="no_ktp">
                            <validation-provider #default="{ errors }" name="Nomor KTP *" :rules="{
                              required: true,
                              min: 16
                            }">
                              <b-input-group>
                                <b-input-group-prepend is-text>
                                  <feather-icon icon="KeyIcon" />
                                </b-input-group-prepend>
                                <cleave id="no_ktp" v-model="form.no_ktp" class="form-control" :raw="true"
                                  :options="options.noKTP" placeholder="Nomor KTP" />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="6" cols="12">
                          <b-form-group label="Nama Lengkap *" label-for="full_name">
                            <validation-provider #default="{ errors }" name="Nama Lengkap *" rules="required">
                              <b-input-group>
                                <b-input-group-prepend is-text>
                                  <feather-icon icon="UserIcon" />
                                </b-input-group-prepend>

                                <b-form-input v-uppercase id="full_name" v-model="form.full_name"
                                  :state="errors.length > 0 ? false : null" placeholder="Nama Lengkap" />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="6" cols="12">
                          <b-form-group label="Email ( Opsional )" label-for="email">
                            <validation-provider #default="{ errors }" name="email" rules="email">
                              <b-input-group>
                                <b-input-group-prepend is-text>
                                  <feather-icon icon="MailIcon" />
                                </b-input-group-prepend>
                                <b-form-input id="email" v-model="form.email" type="email"
                                  :state="errors.length > 0 ? false : null" placeholder="email@sal.com" />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </validation-observer>

          <b-card-text class="mb-1">
            <b-list-group>
              <b-list-group-item class="d-flex">
                <span class="mr-1">
                  <feather-icon icon="ActivityIcon" size="16" />
                </span>
                <span>
                  Demi menjaga
                  <b-badge variant="danger" class="badge-glow button">
                    privasi dan keamanan
                  </b-badge>
                  data pengguna, proses unggah KTP saat ini tidak tersedia
                  <strong><i>sementara</i></strong>
                  sesuai dengan kebijakan privasi yang berlaku.
                </span>
              </b-list-group-item>

              <b-list-group-item class="d-flex">
                <span class="mr-1">
                  <feather-icon icon="KeyIcon" size="17" />
                </span>
                <span>
                  Without the exact key, <i>brute-forcing</i> My data would take
                  <strong>billions of years to be unlocked with current computing
                    power</strong>, so it's safe on any network. <b-badge variant="success" class="badge-glow button">
                    <feather-icon icon="DatabaseIcon" />

                    Transmission data

                  </b-badge> is fully
                  protected.
                </span>
              </b-list-group-item>

              <!-- <b-list-group-item class="d-flex">
                <span class="mr-1">
                  <feather-icon icon="AlertTriangleIcon" size="16" />
                </span>
                <span>

                </span>
              </b-list-group-item> -->
            </b-list-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="12" md="6">
        <b-card>
          <validation-observer ref="yoScheduleRule" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  <b-badge pill variant="primary"> C </b-badge> Schedule IN
                </h5>
                <small class="text-muted">
                  <strong>Enter Your Planning.</strong>
                </small>
              </b-col>
              <b-col>
                <!-- Calendar for selecting the date -->
                <b-form-group label="">
                  <validation-provider #default="{ errors }" name="Schedule IN Date" rules="required">
                    <b-calendar v-model="form.schedule_in_date" block locale="id-ID" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Time picker for selecting the time -->
                <b-form-group label="">
                  <validation-provider #default="{ errors }" name="Schedule IN Time" rules="required">
                    <b-form-timepicker v-model="form.schedule_in_time" now-button reset-button locale="id-ID"
                      :hour12="false" class="mt-2" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <strong>
                  <br />
                  <i>" Every scan tells a story of protection and control. "</i>
                </strong>
                <!-- <p>Selected Date: {{ form.schedule_in_date }}</p>
                <p>Selected Time: {{ form.schedule_in_time }}</p> -->
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-col>

      <b-col cols="12" md="12">
        <b-card>
          <validation-observer ref="yoScheduleOthers" tag="form">
            <b-row>
              <b-col cols="12" class="mb-2">
                <h5 class="mb-0">
                  <b-badge pill variant="primary"> E </b-badge> Others
                </h5>
                <small class="text-muted">
                  <strong>Enter Your Descriptions / Others.</strong>
                </small>
              </b-col>
              <b-col md="6" cols="12">
                <b-form-group label="Alamat *" label-for="address">
                  <validation-provider #default="{ errors }" name="Alamat *" rules="required">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <feather-icon icon="MapIcon" />
                      </b-input-group-prepend>
                      <b-form-input id="address" v-model="form.address" type="address"
                        :state="errors.length > 0 ? false : null"
                        placeholder="C5QH+98X, Rw., Rw. Makmur, Kec. Palaran, Kota Samarinda, Kalimantan Timur 75251" />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" cols="12">
                <b-form-group label="Asal Perusahaan / Instansi" label-for="from_company">
                  <validation-provider #default="{ errors }" name="Asal Perusahaan / Instansi *" rules="required">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <feather-icon icon="MapPinIcon" />
                      </b-input-group-prepend>
                      <b-form-input id="from_company" v-uppercase v-model="form.from_company" type="from_company"
                        :state="errors.length > 0 ? false : null" placeholder="PT SARANA ABADI LESTARI" />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" cols="12">
                <b-form-group label="Ket. / Keperluan *" label-for="ket">
                  <validation-provider #default="{ errors }" name="Ket. / Keperluan *" rules="required">
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <feather-icon icon="CoffeeIcon" />
                      </b-input-group-prepend>
                      <b-form-input id="ket" v-uppercase v-model="form.ket" type="ket"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Example: Monthly Meeting K3A, Daily Checking, etc" />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card>
          <b-form-checkbox v-model="agree">
            Saya telah membaca dan menyetujui
            <b-link href="https://docs.salpalaran.com/visitor/privacy_policy.html" target="_blank">Kebijakan
              Privasi</b-link>.
          </b-form-checkbox>
        </b-card>
      </b-col>

      <b-col cols="12" v-if="agree">
        <b-button id="tour-simpan" :disabled="loadingSaveData" block @click="formSubmitted" variant="primary">
          <b-spinner v-if="loadingSaveData" small />
          <feather-icon v-else icon="CheckCircleIcon" />
          Save & Send QR Code to WhatsApp
          {{ form.phone ? `+62 ${form.phone}` : `` }}
          <span v-if="form.visitor_cat_id">
            after {{ getCategoryName(form.visitor_cat_id) }} has approved.
          </span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { encrypt } from '@core/utils/aes-256-cbc';
import Ripple from 'vue-ripple-directive';
import AppTour from '@core/components/app-tour/AppTour.vue';
import Cleave from 'vue-cleave-component';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us';

import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {
  BSpinner,
  BAlert,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BBadge,
  BFormInput,
  BFormInvalidFeedback,
  BFormFile,
  BInputGroup,
  BInputGroupPrepend,
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BAvatar,
  BCalendar,
  BFormTimepicker,
  BImg
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import { ref } from '@vue/composition-api';
import useJwt from '@/auth/jwt/useJwt';
import Tesseract from 'tesseract.js';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: {
    BSpinner,
    AppTour,
    BAlert,
    BButton,
    BFormTimepicker,
    BCalendar,
    BBadge,
    Cleave,
    BImg,
    BAvatar,
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BInputGroup,
    BInputGroupPrepend,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent
  },

  directives: {
    Ripple
  },
  data() {
    return {
      // encryptedText: '',
      // decryptedText: '',
      agree: false,
      steps: [
        {
          target: '#tour-personal-info #tour-qrcode',
          header: {
            title: '[INFO] Ruese QRCode'
          },
          content:
            'Jika kamu pernah memiliki File QRCode , `Click Reuse QRCode` untuk pengisian data otomatis dan kamu hanya perlu input rencana tanggal kunjungan'
        },
        {
          target: '#tour-badge-info #badge_id',
          header: {
            title: '[1.1] Badge'
          },
          content: 'Pilih badge sebagai tipe kunjungan kamu'
        },
        {
          target: '#tour-badge-info #phone',
          header: {
            title: '[1.2] Nomor Whatsapp'
          },
          content:
            'QRCode nantinya akan dikirim oleh system ke nomor Whatsapp kamu'
        },
        {
          target: '#tour-personal-info #form-ktp-file',
          header: {
            title: '[2.1]File KTP'
          },
          content:
            'Upload KTP kamu dan sistem akan menampilkan nomor KTP yang dapat disalin ke kolom pengisian Nomor KTP *'
        },
        {
          target: '#tour-simpan',
          header: {
            title: 'Simpan'
          },
          content:
            'Setelah pengisian , Klik simpan untuk membuat rencana kunjungan'
        }
      ],
      userData: JSON.parse(localStorage.getItem('userDataVisitor')) ?? [],
      loadingSaveData: false,
      badge: [
        {
          id: 1,
          name: 'Tamu ( Visitor )',
          code: 'A.1',
          desc: 'Umum'
        },
        {
          id: 2,
          name: 'Awak 2 Truck',
          code: 'A.2',
          desc: ''
        },
        {
          id: 3,
          name: 'Identitas / Karyawan / Rutinitas',
          code: 'B',
          desc: ''
        },
        {
          id: 4,
          name: 'Pekerja Harian',
          code: 'C',
          desc: ''
        },
        {
          id: 5,
          name: 'Pengurus Agent BBM',
          code: 'D',
          desc: ''
        }
      ],
      no_ktp_file: {},
      options: {
        phone: {
          // phone: true,
          // phoneRegionCode: 'ID',
          numericOnly: true,
          delimiters: [' ', ' ', ' '],
          blocks: [4, 4, 4]
        },
        noKTP: {
          numericOnly: true,
          delimiters: [' ', ' ', ' '],
          blocks: [4, 4, 4, 4],
          uppercase: true
        }
      },
      /*
      visitor_public --> id badge_id
      visitor_private --> id badge_id

      ----------------
      ( Reuse QRCode update date_expired and status )
      ( sys_auto_renew = true kalau badge_id = 3 = Reuse QRCode )
      ----------------

      visitor_qrcode --> id model model_id qrcode_img status date_expired sys_auto_renew

      visitor_count --> id id_visitor_qrcode clock_in clock_out diff_time

      ----------------
      index agent
      vw_visitor_qrcode_agent --> id qrcode_img status expired visitor_private ( personal information )

      index user
      vw_visitor_qrcode_user -->  id qrcode_img status expired visitor_public ( personal information )
      ----------------
      */
      form: {
        /* personal informations */
        no_ktp_file: '',
        no_ktp: '',
        full_name: '',
        email: '',
        phone: '',

        /* type data / badge
         *
         * Keterangan - Code - Name
         * 1. Umum A.1 Tamu ( Visitor )
         * 2. Jalur Darat A.2 Tamu ( Visitor )
         * 3. '' B Identitas / Karyawan / Rutinitas
         * 4. '' C Pekerja Harian
         * 4. '' 5 Pengurus Agent BBM
         *
         **/
        badge_id: '1',

        /* social media */
        twitter: '',
        facebook: '',
        instagram: '',
        linkedin: '',
        schedule_in_date: '',
        schedule_in_time: '',
        uuid: uuidv4(),
        ket: ''
      },
      required,
      email,
      categoryName: [
        {
          value: '1',
          text: 'Sarana Abadi Lestari (SAL)',
          color: 'success'
        },
        {
          value: '2',
          text: 'Palaran Indah Lestari (PIL)',
          color: 'success'
        },
        {
          value: '3',
          text: 'Sarana Utama Lestari (SUL)',
          color: 'success'
        },
        {
          value: '4',
          text: 'Sarana Palaran Lestari (SPL)',
          color: 'success'
        },
        {
          value: '5',
          text: 'Pertamina Patra Niaga (PATRA)',
          color: 'success'
        },
        // {
        //   value: '6',
        //   text: 'Triputra Energi Megatara (TEM)',
        //   color: 'success'
        // },
        {
          value: '7',
          text: '(SLB)',
          color: 'success'
        },
        {
          value: '8',
          text: 'Palaran Energi Semesta (PES)',
          short: 'PES',
          color: 'success'
        }
      ],
      badgeName: [
        {
          color: '',
          value: 'select_value',
          text: 'Pilih Badge ( Pilihan Terbatas Login Required )'
        },
        {
          color: 'primary',
          value: '1',
          text: '[ A.1 ] Tamu ( Visitor ) - Umum'
        }
        // {
        //   color: 'primary',
        //   value: '2',
        //   text: '[ A.2 ] Awak 2 Truck'
        // },
        // {
        //   color: 'success',
        //   value: '3',
        //   text: '[ B ] Identitas / Karyawan / Rutinitas'
        // },
        // {
        //   color: 'warning',
        //   value: '4',
        //   text: '[ C ] Pekerja Harian'
        // },
        // {
        //   color: 'success',
        //   value: '5',
        //   text: '[ D ] Pengurus Agent BBM'
        // }
      ]
    };
  },
  setup() {
    const refInputEl = ref(null);
    const refPreviewEl = ref(null);
    const text = ref(''); // Create a ref to store the extracted NIK

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
        extractText(base64);
      }
    );

    // Moved extractText function here so it can access text ref
    const extractText = (base64Image) => {
      Tesseract.recognize(
        base64Image,
        'eng', // use 'ind' if the text is in Indonesian
        {
          logger: (m) => {
            console.log(m);
          } // Optional: log progress
        }
      )
        .then(({ data: { text: extractedText } }) => {
          console.log('Extracted Text:', extractedText);

          // Clean the extracted text by removing non-alphanumeric characters
          // (with exceptions for spaces for clearer parsing)
          const cleanedText = extractedText
            .replace(/[^\w\s:]/g, ' ')
            .replace(/\s+/g, ' ')
            .trim();
          console.log('Cleaned Text:', cleanedText);

          // Use regex to extract NIK number
          const nikMatch = cleanedText.match(/NIK\s*:\s*(\w+)/i);

          if (nikMatch && nikMatch[1]) {
            const nik = nikMatch[1]; // get the first capturing group which is the number
            console.log('Extracted NIK:', nik);

            // Check if the first character is 'b' and replace it with '6'
            if (nik[0] === 'b') {
              const updatedNik = '6' + nik.slice(1); // Create a new string with '6' as the first character
              text.value = updatedNik; // Store the updated NIK in the reactive ref
            } else {
              text.value = nik; // Store the extracted NIK in the reactive ref
            }
          } else {
            console.log('NIK not found');
            text.value = '';
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };

    return {
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
      text
    };
  },
  computed: {
    // badgeNameLogin() {
    //   if (this.userData.length !== 0) {
    //     return this.badgeName.map((item) => {
    //       if (item.value === 'select_value') {
    //         return {
    //           color: '',
    //           value: 'select_value',
    //           text: 'Pilih Badge'
    //         }
    //       }
    //       return item
    //     })
    //   } else {
    //     const data = this.badgeName.filter(
    //       (d) => d.value != '3' && d.value != '4' && d.value != '5'
    //     )
    //     return data
    //   }
    // }
  },
  methods: {
    // encryptData() {
    //   console.log('this.form', this.form);
    //   this.encryptedText = encrypt(this.form);
    // },
    // decryptData() {
    //   this.decryptedText = decrypt(this.encryptedText);
    // },
    getCategoryName(id) {
      const category = this.categoryName.find((cat) => cat.value === `${id}`);
      return category ? category.text : 'Unknown Category';
    },
    // tour steps
    tourStart() {
      // Start the tour using the name 'vuexyTour'
      if (this.$tours.vuexyTour) {
        this.$tours.vuexyTour.start();
      } else {
        console.error('Tour instance not found.');
      }
    },
    copyText(string) {
      this.$copyText(string).then(
        () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Text copied : ${string}`,
              variant: 'primary',
              icon: 'CheckCircleIcon'
            }
          });
        },
        (e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Can not copy!`,
              variant: 'danger',
              icon: 'XCircleIcon'
            }
          });
        }
      );
    },
    async formSubmitted() {
      try {
        await this.validationA();
        await this.validationC();
        await this.validationD();
        await this.validationE();
        this.saveData();
      } catch (error) {
        console.log('errors', error);
      }
    },
    async saveData() {
      try {
        this.loadingSaveData = true;
        const formData = new FormData();
        formData.append('file', this.form.no_ktp_file);

        // Trim the phone number and remove any spaces
        let phoneNumber = this.form.phone.trim().replace(/\s+/g, '');

        if (phoneNumber.startsWith('0')) {
          // Remove the leading '0' if it exists
          phoneNumber = phoneNumber.slice(1);
        }

        // Now prepend the country code
        const formattedPhone = `62${phoneNumber}`;
        console.log('sent to : ', formattedPhone);
        const payload = {
          ...this.form,
          phone: `${formattedPhone}`,

          /* for notifications */
          token: this.$store.state.visitor.tokenNotification,
          model: 'visitor_public'
        };

        await useJwt.http
          .post('visitor_public/', {
            sal_protection: encrypt(payload)
          })
          .then(async (result) => {
            try {
              const { id } = result.data.visitor_public;
              await useJwt.http
                .put(
                  `file_storage_ktp/${id}/visitor_public/0`,
                  // `file_storage_ktp/${id}/visitor_public/${payload.no_ktp}`,
                  formData
                )
                .then(async (_result) => {
                  console.log('[post ktp] [0] success on upload ktp', _result);
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'QRCode Telah dikirim ke Whatsapp Kamu',
                      icon: 'SaveIcon',
                      variant: 'success'
                    }
                  });
                  const successData = {
                    full_name: this.form.full_name,
                    phone: this.form.phone
                  };

                  // Store the data in Vuex
                  this.$store.commit('visitor/SET_SUCCESS_DATA', successData);

                  this.$router.push({
                    path: '/planning/create-form-umum/success'
                  });
                  // await this.sentNotification('Published')
                  this.loadingSaveData = false;
                })
                .catch((errors) => {
                  this.loadingSaveData = false;
                  console.log('[post ktp] [1] an errors on upload ktp', errors);
                });
            } catch (errors) {
              this.loadingSaveData = false;
              console.log('[post ktp] [2] an errors on upload ktp', errors);
            }
          })
          .catch((e) => {
            this.loadingSaveData = false;
            console.log('error: ', e);
            console.log('error: ', e.data.message.default);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Tidak berhasil melakukan aksi',
                icon: 'SaveIcon',
                variant: 'danger'
              }
            });
          });
      } catch (error) {
        console.log('an errors', error);
      }
    },
    // async sentNotification(isRevisiMsg) {
    //   await this.setPayloadNotification(isRevisiMsg)
    //   useJwt.http
    //     .post('notification', this.payloadNotification)
    //     .then(() => {})
    //     .catch((error) => {
    //       // console.log('data err: ', err)
    //       this.$swal({
    //         title: 'Error!',
    //         text: ` Form Masuk dan Keluar Berhasil dibuat tetapi ada kesalahan sistem untuk notification! ${error}. Ini tidak berdampak pada kerja sistem utama.`,
    //         icon: 'error',
    //         customClass: {
    //           confirmButton: 'btn btn-primary'
    //         },
    //         buttonsStyling: false
    //       }).then(async () => {
    //         if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
    //         this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
    //       })
    //     })
    // },
    validationA() {
      return new Promise((resolve, reject) => {
        this.$refs.yoBadgeRule.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Form Wajib diisi',
                icon: 'SaveIcon',
                variant: 'danger'
              }
            });
            reject();
          }
        });
      });
    },
    validationC() {
      return new Promise((resolve, reject) => {
        this.$refs.yoPersonalInfoRule.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Form Wajib diisi',
                icon: 'SaveIcon',
                variant: 'danger'
              }
            });
            reject();
          }
        });
      });
    },
    validationD() {
      return new Promise((resolve, reject) => {
        this.$refs.yoScheduleRule.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Form Wajib diisi',
                icon: 'SaveIcon',
                variant: 'danger'
              }
            });
            reject();
          }
        });
      });
    },
    validationE() {
      return new Promise((resolve, reject) => {
        this.$refs.yoScheduleOthers.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Form Wajib diisi',
                icon: 'SaveIcon',
                variant: 'danger'
              }
            });
            reject();
          }
        });
      });
    }
  }
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.yo-guide {
  position: fixed;
  bottom: 4%;
  right: 20px;
  z-index: 1031;

  .btn {
    box-shadow: 0 0 20px 5px rgba(115, 103, 240, 0.8) !important;
    transition: box-shadow 0.3s ease-in-out;

    // Glow animation effect
    animation: glow 1.5s infinite alternate;

    &:hover {
      box-shadow: 0 0 10px 3px rgba(115, 103, 240, 1) !important;
      animation: none; // Stop glowing effect on hover
    }
  }
}

// Keyframes for glowing pulse effect
@keyframes glow {
  0% {
    box-shadow: 0 0 20px 5px rgba(115, 103, 240, 0.8);
  }

  100% {
    box-shadow: 0 0 30px 10px rgba(115, 103, 240, 1);
  }
}
</style>